<template>
  <section class="dashboard-edit">
    <div class="container">
      <div class="box box-solid">
        <div class="nav-tabs-custom">
          <ul class="nav nav-tabs pull-right ui-sortable-handle" ref="nav">
            <li
              v-bind:class="{active: currentTab == 'tab_casualty_list'}"
              @click="currentTab = 'tab_casualty_list'"
            >
              <a
                href="#tab_casualty_list"
                data-testid="casualty-list-tab"
                aria-expanded="true"
                data-toggle="tab"
              >
                <i class="fa fa-times-circle"></i> {{ $t("casualty_list") }}
              </a>
            </li>
            <li
              v-bind:class="{active: currentTab == 'tab_reason_list'}"
              @click="currentTab = 'tab_reason_list'"
            >
              <a
                href="#tab_reason_list"
                data-testid="reason-list-tab"
                aria-expanded="true"
                data-toggle="tab"
              >
                <i class="fa fa-hand-paper-o"></i> {{ $t("reason_list") }}
              </a>
            </li>
          </ul>
          <div class="tab-content panel-content" ref="tabs">
            <div
              id="tab_reason_list"
              class="tab-pane"
              v-bind:class="{active: currentTab == 'tab_reason_list'}"
            >
              <FormReason />
            </div>
            <div
              id="tab_casualty_list"
              class="tab-pane"
              v-bind:class="{active: currentTab == 'tab_casualty_list'}"
            >
              <FormCasualty />
            </div>
          </div>
        </div>
        <Spin v-if="busy" />
      </div>
    </div>
  </section>
</template>

<script>
import Spin from "@/components/spin.vue";
import FormReason from "@/components/registration/form-reason.vue";
import FormCasualty from "@/components/registration/form-casualty.vue";

export default {
  name: "DashboardEditOnlineeConfigForm",
  components: {
    Spin,
    FormReason,
    FormCasualty
  },
  props: {},
  data() {
    return {
      currentTab: "tab_reason_list",
      nav: null,
      busy: true
    };
  },
  computed: {},
  methods: {
    onNavChanged(nav) {
      if (this.connectorId && this.deviceId) {
        nav = nav || {};
        nav.showTabLabels = true;
        nav.tabs = {
          items: [
            {
              id: "list",
              icon: "glyphicon glyphicon-stats",
              title: "data_list",
              label: this.$tc("tab_label.data", 2)
            },
            {
              id: "edit",
              icon: "portal-icon device[solid]",
              title: "edit",
              label: this.$tc("tab_label.device", 1)
            }
          ]
        };
      }
      // this.$emit("navChanged", nav);
      this.$set(this, "nav", nav);
    }
  },
  mounted() {
    this.busy = false;
  }
};
</script>

<style>
section > .tab-pane {
  margin: 5px 0 30px 0;
  padding: 10px 15px 50px 15px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.dashboard-edit {
  /*
    prevent content "jump" when scrollbar is visibile 
    (https://aykevl.nl/2014/09/fix-jumping-scrollbar)
  */
  padding-left: calc(100vw - (100% + 60px));
}

@media (min-width: 1440px) {
  .dashboard-edit > .container {
    width: 1380px;
  }
}

@media (min-width: 1730px) {
  .dashboard-edit > .container {
    width: 1690px;
  }
}
</style>
